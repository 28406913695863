export const requestTypes = [
    { label: 'Contestarea actelor și acțiunilor executorului judecătoresc', value: 'Contestare a actelor și acțiunilor executorului judecătoresc' },
    { label: 'Cereri asociate cu cerința de suspendare a executării', value: 'Asociate cu cerința de suspendare a executării' },
    { label: 'Desfacerea căsătoriei', value: 'De chemare în judecată privind desfacerea căsătoriei' },
    { label: 'Stabilirea domiciliului copilului minor', value: 'De stabilire a domiciliului copilului minor' },
    { label: 'Modificarea sau revocarea contractului de locațiune a încăperilor de locuit', value: 'De chemare în judecată privind modificarea sau revocarea contractului de locațiune a încăperilor de locuit' },
    { label: 'Prelungirea termenului de primire a moștenirii', value: 'Prelungirea termenului de primire a moștenirii' },
    { label: 'Ridicarea sechestrului de pe bunuri', value: 'Ridicarea sechestrului de pe bunuri' },
    { label: 'Revocarea contractelor fără revendicarea bunurilor', value: 'De chemare în judecată privind revocarea contractelor fără revendicarea bunurilor' },
    { label: 'Alte acțiuni cu caracter nepatrimonial (care nu urmează a fi evaluate)', value: 'De chemare în judecată în cazul acțiunilor cu caracter nepatrimonial (sau al celor care nu urmează a fi evaluate)' },
    { label: 'Cererile (plângerile) privind cauzele cu procedură specială', value: '(Plângerile) privind cauzele cu procedură specială' },
    { label: 'Examinarea chestiunilor ce țin de apărarea onoarei și demnității', value: 'De chemare în judecată privind examinarea chestiunilor ce țin de apărarea onoarei și demnității' },
    { label: 'Confirmarea tranzacției încheiate în afara procesului civil', value: 'Confirmarea, de către instanța de judecată, a tranzacției încheiate în afara procesului civil' },
    { label: 'Eliberarea înregistrării audio a ședinței de judecată', value: 'Eliberarea înregistrării audio a ședinței de judecată' }
];

export const insolvencyTypes = [
    { label: "Introductive depuse de creditor sau debitor privind intentarea procesului de insolvabilitate", value:"Introductive depuse de creditor sau debitor privind intentarea procesului de insolvabilitate"},
    { label:"Acțiunile cu caracter patrimonial depuse în cadrul procedurilor de insolvabilitate", value:"Acțiunile cu caracter patrimonial depuse în cadrul procedurilor de insolvabilitate"}
];

export const beneficiari = [
    {
      label: "Curtea de Apel Nord",
      iban: "MD14TRPAAA142310A01189AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000399",
      value: 1
    },
    {
      label: "Curtea de Apel Sud",
      iban: "MD13TRPAAA142310A01191AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601004389",
      value: 2
    },
    {
      label: "Curtea de Apel Centru",
      iban: "MD76TRPAAA142310A14839AA",
      bic: "TREZMD2X",
      cod_fiscal: "1013601000059",
      value: 3
    },
    {
      label: "Curtea de Apel Comrat",
      iban: "MD61TRPAAA142310A01192AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000702",
      value: 4
    },
    {
      label: "Judecătoria Anenii Noi",
      iban: "MD08TRPAAA142310A01201AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601011423",
      value: 5
    },
    {
      label: "Judecătoria Bălți",
      iban: "MD58TRPAAA142310A01198AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000182",
      value: 6
    },
    {
      label: "Judecătoria Cahul",
      iban: "MD55TRPAAA142310A01204AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601004677",
      value: 7
    },
    {
      label: "Judecătoria Căușeni",
      iban: "MD05TRPAAA142310A01207AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000207",
      value: 8
    },
    {
      label: "Judecătoria Chișinău",
      iban: "MD25TRPAAA142310A15232AA",
      bic: "TREZMD2X",
      cod_fiscal: "1016601000256",
      value: 9
    },
    {
      label: "Judecătoria Cimișlia",
      iban: "MD04TRPAAA142310A01209AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000610",
      value: 10
    },
    {
      label: "Judecătoria Criuleni",
      iban: "MD03TRPAAA142310A01211AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601001248",
      value: 11
    },
    {
      label: "Judecătoria Comrat",
      iban: "MD52TRPAAA142310A01210AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000263",
      value: 12
    },
    {
      label: "Judecătoria Edineț",
      iban: "MD98TRPAAA142310A01215AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601004932",
      value: 13
    },
    {
      label: "Judecătoria Drochia",
      iban: "MD02TRPAAA142310A01213AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000230",
      value: 14
    },
    {
      label: "Judecătoria Hîncești",
      iban: "MD96TRPAAA142310A01219AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601003120",
      value: 15
    },
    {
      label: "Judecătoria Orhei",
      iban: "MD45TRPAAA142310A01224AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601005216",
      value: 16
    },
    {
      label: "Judecătoria Soroca",
      iban: "MD09TRPAAA142310A01199AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000241",
      value: 17
    },
    {
      label: "Judecătoria Strășeni",
      iban: "MD43TRPAAA142310A01228AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601001167",
      value: 18
    },
    {
      label: "Judecătoria Ungheni",
      iban: "MD89TRPAAA142310A01233AA",
      bic: "TREZMD2X",
      cod_fiscal: "1014601000104",
      value: 19
    },
    {
      label: "Curtea Supremă de Justiție",
      iban: "MD46TRPAAA142310A14899AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601004183",
      value: 20
    },
  ];
  
  export const beneficiariMod = [
    {
      label: "Curtea Supremă de Justiție",
      iban: "MD46TRPAAA142310A14899AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601004183",
      value: 20
    },
    {
      label: "Curtea de Apel Nord",
      iban: "MD14TRPAAA142310A01189AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000399",
      value: 1
    },
    {
      label: "Curtea de Apel Sud",
      iban: "MD13TRPAAA142310A01191AA",
      bic: "TREZMD2X",
      cod_fiscal: "1006601004389",
      value: 2
    },
    {
      label: "Curtea de Apel Centru",
      iban: "MD76TRPAAA142310A14839AA",
      bic: "TREZMD2X",
      cod_fiscal: "1013601000059",
      value: 3
    },
    {
      label: "Curtea de Apel Comrat",
      iban: "MD61TRPAAA142310A01192AA",
      bic: "TREZMD2X",
      cod_fiscal: "1007601000702",
      value: 4
    }
  ];