import React from 'react'
import { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import {Popover, ArrowContainer} from '../UI/popover/Popover.tsx'
import { FormData } from '../context/FormContextData';
export default function DetailsPopOver( {rowFormData,result}) {
    const {instanceType,personType} = useContext(FormData);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const personTypeMinFee = ()=>{
       if(personType)   return 150; else return 250;
    }
    const superiorStep = () => {
        if (rowFormData.actionSum <= 5000) {
          return 5000;
        } else if (rowFormData.actionSum <= 50000) {
          return 50000;
        } else if (rowFormData.actionSum <= 1500000) {
          return 1500000;
        } else if (rowFormData.actionSum <= 5000000) {
          return 5000000;
        } else {
          return 10000000;
        }
      };
      const baseFee = () => {
        if (rowFormData.actionSum <= 5000) {
          return 0;
        } else if (rowFormData.actionSum <= 50000) {
          return 250;
        } else if (rowFormData.actionSum <= 1500000) {
          return 2050;
        } else if (rowFormData.actionSum <= 5000000) {
          return 45550;
        } else if(rowFormData.actionSum <= 10000000){
          return 115550;
        }else{
            return 165550;
        }
      };
      const feePercent = () => {
        if (rowFormData.actionSum <= 5000) {
          return 0;
        } else if (rowFormData.actionSum <= 50000) {
          return 0.04;
        } else if (rowFormData.actionSum <= 1500000) {
          return 0.03;
        } else if (rowFormData.actionSum <= 5000000) {
          return 0.02;
        } else if(rowFormData.actionSum <= 10000000){
          return 0.01;
        }else{
            return 0.005;
        }
      };
      const inferiorStep = () => {
        if (rowFormData.actionSum <= 5000) {
          return 0;
        } else if (rowFormData.actionSum <= 50000) {
          return 5001;
        } else if (rowFormData.actionSum <= 1500000) {
          return 50001;
        } else if (rowFormData.actionSum <= 5000000) {
          return 1500001;
        } else if(rowFormData.actionSum <= 10000000){
          return 5000001;
        }else{
          return 10000001;
        }
      };
    const clickMeButtonRef = useRef(HTMLButtonElement | undefined);
  return (
    <div className="details">

                                <Popover 
                                  isOpen={isPopoverOpen} 
                                  positions={['right', 'bottom', 'top']} 
                                  padding={10} ref={clickMeButtonRef}
                                  content={({position, childRect, popoverRect}) => (
                                      <ArrowContainer
                                        position={position} 
                                        childRect={childRect} 
                                        popoverRect={popoverRect} 
                                        arrowColor={'white'} 
                                        arrowSize={10} 
                                        className='popover-arrow-container' 
                                        arrowClassName='popover-arrow'>
                                          <div
                                              style={{
                                                  backgroundColor: 'white',
                                                  width: '100%',
                                                  borderRadius: '5px',
                                                  border: '1.5px solid lightgrey',
                                                  fontFamily: 'Montserrat, sans-serif',
                                                  fontSize: '14px',
                                                  lineHeight: '1.6'
                                              }}
                                            >
                                                <div
                                                  className='header'
                                                  style={{
                                                      height:'40px',
                                                      backgroundColor: 'lightgrey',
                                                      padding: '0px 15px 0px 15px',
                                                      alignItems: 'center',
                                                      display: 'flex'
                                                  }}>
                                                    Detalii
                                                </div>
                                              <div
                                                  className='body'
                                                  style={{
                                                    padding: '12px 15px 12px 15px',
                                                    alignItems: 'center'
                                                  }}>
                                                    <ul >
                                                      <b>Identificarea valorii acțiunii:</b>
                                                      <li>Valoarea acțiunii este de  {rowFormData.actionSum} lei.</li>
                                                      <b>Determinarea pragului de calcul:</b>
                                                      {
                                                        rowFormData.actionSum <= 5000 ?
                                                        <li>Conform legii taxei de stat, Nr. 213 din 31-07-2023, pentru o valoare a acțiunii mai mică sau egală cu 5000 lei, <br /> taxa de stat se calculează astfel: 5% din valoarea acțiunii, dar nu mai puțin de {personTypeMinFee()} lei pentru persoanele {personType ? "fizice" : "juridice"}.</li>
                                                        :
                                                        <li>Conform legii taxei de stat, Nr. 213 din 31-07-2023, pentru o valoare a acțiunii cuprinsă între {inferiorStep()} și {superiorStep()} lei,<br />
                                                        taxa de stat se calculează astfel:
                                                        {baseFee()} lei + {feePercent() * 100}% din valoarea care depășește {inferiorStep()} lei.
                                                        </li>
                                                      }
                                                      {rowFormData.actionSum <= 5000 ?
                                                        <>
                                                        <b>Calculul taxei pentru persoanele {personType ? "fizice" : "juridice"}:</b>
                                                        <li>Calculăm 5% din valoarea acțiunii: <br />
                                                        0.05% x {rowFormData.actionSum} = {(rowFormData.actionSum * 0.05).toFixed(2)} lei.
                                                        </li>
                                                        <li>Verificăm dacă taxa calculată este mai mică de {personTypeMinFee()} lei: <br />
                                                        {(rowFormData.actionSum * 0.05).toFixed(2)} lei {"<"} {personTypeMinFee()} lei {!((rowFormData.actionSum * 0.05) < personTypeMinFee()) && "nu"} este adevărat. <br />
                                                        Taxa de stat este {result} lei.
                                                        </li>
                                                        </>
                                                        :
                                                        <>
                                                        <b>Calculul diferenței:</b>
                                                      <li>Mai întâi, determinăm cât din valoarea acțiunii depășește {inferiorStep()} lei: <br />
                                                      {rowFormData.actionSum} lei - {inferiorStep()} lei = {rowFormData.actionSum - inferiorStep()} lei.
                                                      </li>
                                                      <b>Calculul procentului de {feePercent() * 100}%</b>
                                                      <li>Apoi, calculăm {feePercent() * 100}%  din această diferență: <br />
                                                      {feePercent() * 100}% x {rowFormData.actionSum - inferiorStep()} lei = {((rowFormData.actionSum - inferiorStep()) * feePercent()).toFixed(2)} lei.
                                                      </li> 
                                                      <b>Adăugarea taxei de bază:</b>
                                                      <li>La această sumă, adăugăm taxa de bază de {baseFee()} lei: <br />
                                                      {baseFee()} lei + {(rowFormData.actionSum - inferiorStep()) * feePercent()} lei = {(baseFee() + (rowFormData.actionSum - inferiorStep()) * feePercent()).toFixed(2)} lei.
                                                      </li>
                                                      {instanceType == "Apel" && 
                                                      <>
                                                      <b>Calculul taxei pentru apel:</b>
                                                      <li>Calculăm 85% din taxa de stat achitată în prima instanță: <br />
                                                      0.85 x  {(baseFee() + (rowFormData.actionSum - inferiorStep()) * feePercent()).toFixed(2)} lei = {result} lei.
                                                      </li>
                                                      </>}
                                                      {instanceType == "Recurs" && 
                                                      <>
                                                      <b>Calculul taxei pentru apel:</b>
                                                      <li>Calculăm 70% din taxa de stat achitată în prima instanță: <br />
                                                      0.70 x  {(baseFee() + (rowFormData.actionSum - inferiorStep()) * feePercent()).toFixed(2)} lei = {result} lei.
                                                      </li>
                                                      </>}
                                                      {instanceType == "Revizuire" && 
                                                      <>
                                                      <b>Calculul taxei pentru apel:</b>
                                                      <li>Calculăm 55% din taxa de stat achitată în prima instanță: <br />
                                                      0.55 x  {(baseFee() + (rowFormData.actionSum - inferiorStep()) * feePercent()).toFixed(2)} lei = {result} lei.
                                                      </li>
                                                      </>}
                                                        </>
                                                      }
                                                    </ul>
                                              </div>
                                          </div>
                                      </ArrowContainer>
                                    )}>
                                    <u
                                        onMouseEnter={() => setIsPopoverOpen(true)}
                                        onMouseLeave={() => setIsPopoverOpen(false)}
                                    >
                                        Detalii
                                    </u>
                                </Popover>
                            </div>
  )
}
